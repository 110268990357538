import React from 'react';
import PropTypes from 'prop-types';
import Components from '@components/Components';
import imageUrlFor from '@helpers/imageUrlFor';
import HeroAnimation from '@components/HeroAnimation';
import Link from '@utility/Link';
import SEO from '@components/seo';
import HeroSectionHome from '@src/components/HeroSectionHome';

const HomePageTemplate = ({
  pageContext: {
    _rawComponents,
    title,
    description,
    metaImage,
    _rawHeroAnimation,
    _rawHeroSlides
  },
}) => {
  return (
    <div>
      <SEO
        title={title}
        description={description}
        image={metaImage?.asset ? imageUrlFor(metaImage).url() : null}
      />
      <div className="transition-colors duration-500 ease-out">
        {/* <HeroAnimation data={_rawHeroAnimation} /> */}
        <HeroSectionHome slides={_rawHeroSlides} />
      </div>
      <div className="md:mt-12">
        {_rawComponents &&
          _rawComponents.map((element) => (
            <div className="py-12 md:py-20" key={element._key}>
              {React.createElement(Components(element._type), {
                key: element._key,
                data: element,
              })}
            </div>
          ))}
      </div>
      {process.env.NODE_ENV === 'development' && (
        <>
          <ul>
            <li>{process.env.SANITY_PROJECT_ID}</li>
            <li>{process.env.SANITY_PROJECT_DATASET}</li>
          </ul>
          <Link to="/notfound/">View available routes</Link>
        </>
      )}
    </div>
  );
};

HomePageTemplate.propTypes = {
  pageContext: PropTypes.shape({
    _rawComponents: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    metaImage: PropTypes.object,
    _rawHeroAnimation: PropTypes.object,
  }),
};

HomePageTemplate.defaultProps = {
  pageContext: {
    _rawComponents: [],
    metaImage: {},
    _rawHeroAnimation: {},
  },
};
export default HomePageTemplate;
