// extracted by mini-css-extract-plugin
export var bullet = "styles-module--bullet--6b53e";
export var carouselSlide = "styles-module--carouselSlide--d3f10";
export var curatedListContainer = "styles-module--curatedListContainer--0799f";
export var curatedListOuter = "styles-module--curatedListOuter--dbbef";
export var fade = "styles-module--fade--dea6f";
export var heroSectionWrapper = "styles-module--heroSectionWrapper--35f7c";
export var heroSectionWrapperCarousel = "styles-module--heroSectionWrapperCarousel--d4250";
export var hotspot = "styles-module--hotspot--348e2";
export var productInfoImg = "styles-module--productInfoImg--56ea7";
export var productInfoWrapper = "styles-module--productInfoWrapper--a7b91";
export var testContainer = "styles-module--testContainer--24269";
export var testContent = "styles-module--testContent--11c56";
export var top0 = "styles-module--top0--7d8a0";