// Note 24: This component replaces HeroAnimation component as part of home page redesign 2024
import React, { useEffect, useRef, useState } from 'react';
import * as styles from './styles.module.scss';
import imageUrlBuilder from '@helpers/imageUrlFor';
import Link from '@utility/Link';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper';
import CloseIcon from '../../../static/images/icons/icon-close-white.svg';
import ViewProductsIcon from '../../../static/images/icons/view-products.svg';
import cn from 'classnames';
import ProductInfo from './productInfo';
import { productImageSrc } from '@src/helpers/product';
import { isSafariBrowser2 } from '@src/helpers/isSafari';

const HeroSectionHome = ({ slides }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isCuratedListOpen, setCuratedListOpen] = useState(false);
  const swiperRef = useRef();

  const togglerCuratedProducts = () => {
    setCuratedListOpen(!isCuratedListOpen);
  };

  const scrollToSpecificProduct = (product) => {
    setCuratedListOpen(true);
  };

  if (!slides?.length) {
    return <></>;
  }

  return (
    <div
      className={styles.heroSectionWrapper}
      style={{
        backgroundImage: `url(${imageUrlBuilder(
          slides[currentIndex]?.slideImg,
        ).url()})`,
      }}
    >
      {/* <img
        src={slides[currentIndex]?.slideImg?.asset?.url}
        alt=""
        className="w-full object-cover h-[95vh] md:h-auto"
      /> */}

      {/* Preloading bacgrkound images by adding to img tag dummy */}
      {slides.map((item, index) => (
        <img
          src={imageUrlBuilder(item?.slideImg).url()}
          alt=""
          className="hidden"
        />
      ))}

      {/* Hotspots - Hiding to show after 18/03/2024*/}
      {/* <div className="w-full h-full absolute top-0 z-1">
        <div>
          {slides[currentIndex].hotspots.map((spot, index) => (
            <div
              className={styles.hotspot}
              style={{ left: `${spot.x}%`, top: `${spot.y}%` }}
              onClick={() => scrollToSpecificProduct(spot.product)}
            ></div>
          ))}
        </div>
      </div> */}

      <div className="wrapper w-full h-[100vh] absolute top-0 left-0 right-0 m-auto">
        {/* Hero carousel */}
        <div
          className={cn(styles.heroSectionWrapperCarousel, {
            ['float-right']: slides[currentIndex].reverseOverlay,
            ['sm-down:bottom-[60px]']: isSafariBrowser2(),
          })}
        >
          <Swiper
            ref={swiperRef}
            spaceBetween={130}
            centeredSlides={true}
            autoplay={{
              delay: 8000,
              disableOnInteraction: true,
            }}
            // loop={true}
            pagination={{
              el: '.swiper-pagination',
              clickable: true,
              // renderBullet: function (index, className) {
              //   return (
              //     '<span class="' +
              //     className +
              //     ' ' +
              //     styles.bullet +
              //     '"></span>'
              //   );
              // },
            }}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
              enabled: true,
            }}
            onSlideChange={(e) => setCurrentIndex(e.activeIndex)}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
            watchSlidesProgress={true}
            // fadeEffect={{
            //   crossFade: true,
            // }}
            speed={1500}
          >
            {slides.map((slide, index) => (
              <SwiperSlide>
                <div className={styles.carouselSlide}>
                  <p className="type-sans-630 md:type-sans-830 font-bold">
                    {slide.heading}
                  </p>
                  <p className="type-sans-030 mt-5 hidden md:block">
                    {slide.text}
                  </p>

                  <p className="type-sans-030 my-6 md:my-10">
                    <Link
                      internalExternal
                      to={slide.link[0]}
                      // styled={false}
                      // className="link"
                      aria-label={slide.link[0].label}
                    >
                      {slide.link[0].label}
                    </Link>
                  </p>
                </div>
              </SwiperSlide>
            ))}
            <button
              className="swiper-button-next !hidden"
              aria-label="Next Slide"
            ></button>
            <button
              className="swiper-button-prev !hidden"
              aria-label="Previous Slide"
            ></button>
            <div className="swiper-pagination !w-[80%]"></div>
          </Swiper>
        </div>

        {/* Curated list - Hiding to show after 18/03/2024 */}
        {/* <div className="w-80 md:w-auto left-0 md:left-auto m-auto absolute right-0 bottom-2">
          {isCuratedListOpen &&
            slides[currentIndex].curatedProducts?.length > 0 && (
              <div className="-mt-6 flex justify-end relative z-[1000]">
                <p
                  className="float-right flex items-center justify-end"
                  onClick={() => setCuratedListOpen(false)}
                >
                  <img src={CloseIcon} alt="Close icon" />
                  <span className="text-white type-sans-140">Close</span>
                </p>
              </div>
            )}
          Curated popup
          <div
          className={cn(
            styles.curatedListOuter,
            'w-full md:w-72 overflow-scroll',
            {
              [styles.top0]: isCuratedListOpen,
              ['hidden']: slides[currentIndex].curatedProducts?.length < 1
            },
          )}>
            {slides[currentIndex].curatedProducts?.length > 0 && (
              <div
                className={cn(
                  styles.curatedListContainer,
                  // 'w-full md:w-72 overflow-scroll',
                  // {
                  //   [styles.top0]: isCuratedListOpen,
                  // },
                )}
                onMouseEnter={() =>
                  swiperRef?.current?.swiper?.autoplay?.pause()
                }
                onMouseLeave={() => swiperRef?.current?.swiper?.autoplay?.run()}
              >
                {slides.length > 0 &&
                  slides[currentIndex].curatedProducts?.map(
                    (product, index) => (
                      <div className="border-b border-b-mono-100">
                        <ProductInfo
                          data={{
                            title: product.title,
                            price: product.price,
                            collection: product.collection,
                            imageUrl: productImageSrc(
                              product.slugPrefix
                                ? product.slugPrefix.current
                                : product.title, // title as fallback so insufficient data does not break the product card
                              'plp',
                              product.partial_sku,
                              [],
                              280,
                            ),
                          }}
                        />
                      </div>
                    ),
                  )}
              </div>
            )}
          </div>

          Curated list trigger
          {slides[currentIndex].curatedProducts?.length > 0 && (
            <a
              className="float-right mt-32 md:mt-0 mb-20 text-white type-sans-130"
              onClick={togglerCuratedProducts}
            >
              <img src={ViewProductsIcon} className='inline-block mr-1'/>
              View products
            </a>
          )}
        </div> */}
      </div>
    </div>
  );
};

export default HeroSectionHome;
