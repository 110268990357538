import ThemeContext from '@context/ThemeContext';
import FluidImage from '@utility/FluidImage';
import Link from '@utility/Link';
import useScrollPosition from '@utility/useScrollPosition';
import cn from 'classnames';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useInView } from 'react-intersection-observer';
import * as styles from './styles';

const HeroAnimation = ({
  data: { heading, link, imageDark, imageMedium, imageLight },
}) => {
  const [scrollTriggerPoint, setscrollTriggerPoint] = useState(false);
  const [scrollTriggerEnd, setSrollTriggerEnd] = useState(false);
  const [animateIn, setAnimateIn] = useState(false);
  const ref = useRef();
  const [inViewRef, inView] = useInView({ triggerOnce: true });
  const setRefs = useCallback(
    (node) => {
      ref.current = node;
      inViewRef(node);
    },
    [inViewRef],
  );
  useScrollPosition(
    ({ currPos }) => {
      if (ref.current) {
        if (currPos > 50) {
          setscrollTriggerPoint(true);
        } else {
          setscrollTriggerPoint(false);
        }
        if (currPos > 100) {
          setSrollTriggerEnd(true);
        } else {
          setSrollTriggerEnd(false);
        }
      }
    },
    [inView],
    0,
    true,
  );
  const { isDark, isSemiDark } = useContext(ThemeContext);
  useEffect(() => {
    isDark(true);
    isSemiDark(false);
    if (inView) {
      setTimeout(() => setAnimateIn(true), 100);
      if (scrollTriggerPoint) {
        isDark(false);
        isSemiDark(true);
      }
      if (scrollTriggerEnd) {
        isSemiDark(false);
      }
    }
    return () => {
      isDark(false);
      isSemiDark(false);
    };
  }, [
    scrollTriggerEnd,
    scrollTriggerPoint,
    inView,
    isDark,
    isSemiDark,
    animateIn,
  ]);

  const memoText = useMemo(
    () => (
      <div className="col-12 sm:col-10 lg:col-4 lg:offset-1">
        <div
          className={cn(styles.heroContentWrapper, {
            [styles.heroContentWrapperIn]: animateIn,
          })}
        >
          <div>
            <h1 className={styles.heroHeading}>{heading}</h1>
            {link && link[0] && (
              <p className="mt-8 md:mt-16">
                <Link internalExternal to={link[0]} aria-label={link[0].label}>
                  {link[0].label}
                </Link>
              </p>
            )}
          </div>
        </div>
      </div>
    ),
    [heading, link, animateIn],
  );
  return (
    <div ref={setRefs}>
      <div className="wrapper">
        <div className={cn('row', styles.hero)}>
          {memoText}
          <div className={cn('col-12 lg:col-5', styles.heroColumn)}>
            <div
              className={cn(styles.heroImageWrapper, {
                [styles.heroImageWrapperIn]: animateIn,
              })}
            >
              <div
                className={cn(styles.heroImageWrapperInner, {
                  [styles.heroImageWrapperInnerTrigger]: scrollTriggerPoint,
                  [styles.heroImageWrapperInnerEnd]: scrollTriggerEnd,
                })}
              >
                <div className={styles.heroImage}>
                  <FluidImage
                    src={imageLight}
                    maxWidth={1200}
                    maxHeight={1200}
                    className={styles.heroAsset}
                  />
                </div>
                <div className={styles.heroImage}>
                  <FluidImage
                    src={imageMedium}
                    maxWidth={1200}
                    maxHeight={1200}
                    className={styles.heroAsset}
                  />
                </div>
                <div className={styles.heroImage}>
                  <FluidImage
                    src={imageDark}
                    maxWidth={1200}
                    maxHeight={1200}
                    className={styles.heroAsset}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

HeroAnimation.propTypes = {
  data: PropTypes.shape({
    heading: PropTypes.string.isRequired,
    link: PropTypes.arrayOf(PropTypes.object),
    imageDark: PropTypes.shape({
      asset: PropTypes.shape({
        _id: PropTypes.string,
      }),
    }).isRequired,
    imageMedium: PropTypes.shape({
      asset: PropTypes.shape({
        _id: PropTypes.string,
      }),
    }).isRequired,
    imageLight: PropTypes.shape({
      asset: PropTypes.shape({
        _id: PropTypes.string,
      }),
    }).isRequired,
  }),
};
HeroAnimation.defaultProps = {
  data: {
    link: [],
  },
};
export default HeroAnimation;
